import AppRoutes from './AppRoutes';
import { MenuContextProvider } from './influencer-portal/context/MenuContext';
import { UserContextProvider } from './influencer-portal/context/UserContext';

function App() {
  return (
    <MenuContextProvider>
      <UserContextProvider>
        <AppRoutes />
      </UserContextProvider>
    </MenuContextProvider>
  );
}

export default App;
