import React, { createContext, useState } from "react";

export type MenuState = {
    isOpen: boolean,
    activeMenu?: string
}

export type MenuStateContext = {
    menu?: MenuState,
    setMenu?: (key: string, payload: unknown) => void
}

export const MenuContext: React.Context<MenuStateContext> = createContext<MenuStateContext>({});

/**
 * This is the Global state object and method to set the global state
 */
export const MenuContextProvider = (props: { children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
    const [menu, setState] = useState<MenuState>({isOpen: false});

    const setMenu = (key: string, payload: unknown) => {
        const _menu = {...menu, [key]: payload}
        setState(_menu)
    }

    return (
        <MenuContext.Provider
            value={{
                menu,
                setMenu
            }}
        >
            {props.children}
        </MenuContext.Provider>
    );
};
