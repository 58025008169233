import {
    HashRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import { Suspense, lazy } from "react";

const HomePage = lazy(() => import('./common/pages/HomePage'));
const LoginPage = lazy(() => import("./influencer-portal/pages/LoginPage"));
const SalesPge = lazy(() => import("./influencer-portal/pages/SalesPage"));
const CampaignsPage= lazy(() => import("./influencer-portal/pages/CampaignsPage"));
const ExpensesPage = lazy(() => import("./influencer-portal/pages/ExpensesPage"));

const MemberPage = lazy(() => import("./influencer-portal/pages/MemberPage"));
const InfluencerRegisterPage =  lazy(() => import("./influencer-portal/pages/InfluencerRegisterPage"));
const BrandRegisterPage =  lazy(() => import("./common/pages/BrandRegisterPage"));
const Privacy =  lazy(() => import("./common/pages/Privacy"));
const Terms =  lazy(() => import("./common/pages/Terms"));
const CookiePolicy =  lazy(() => import("./common/pages/CookiePolicy"));

const BackOperationsLogin =  lazy(() => import("./back-operations-portal/pages/OperationsLoginPage"));
const BackOperationsDashboard =  lazy(() => import("./back-operations-portal/pages/OperationsDashboard"));

const GameContainerPage = lazy(() => import("./games/pages/GameContainerPage"));
const GameEntrySubmitPage = lazy(() => import("./games/pages/GameEntrySubmitPage"));
const PuzzleContainerPage = lazy(() => import("./games/pages/PuzzleContainerPage"));
const PuzzleTest = lazy(() => import("./games/pages/PlainPuzzle"));

const AppRoutes = () => (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/** Influencer Portal Routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sales" element={<SalesPge />} />
          <Route path="/campaigns" element={<CampaignsPage />} />
          <Route path="/dashboard" element={<MemberPage />} />
          <Route path="/expenses" element={<ExpensesPage />} />

          {/** Home page utilities routes */}
          <Route path="/register-influencer" element={<InfluencerRegisterPage />} />
          <Route path="/register-brand" element={<BrandRegisterPage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cookie" element={<CookiePolicy />} />
          
          {/** The operations routes */}
          <Route path="/backop" element={<BackOperationsLogin />} />
          <Route path="/operations-dashboard" element={<BackOperationsDashboard />} />

          {/* Games */}
          <Route path="/games/blink" element={<GameContainerPage />} />
          <Route path="/games/blink/submit" element={<GameEntrySubmitPage game="games/blink" />} />
          
          <Route path="/karthiksurya/jigsaw" element={<PuzzleContainerPage />} />
          <Route path="/karthiksurya/jigsaw/submit" element={<GameEntrySubmitPage game="karthiksurya/jigsaw"/>} />
        </Routes>
      </Suspense>
    </Router>
  );

export default AppRoutes